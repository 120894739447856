<template>
    <div class="b2c-page-bg">
        <div class="b2c-pc-header">
            <h2><a @click="goToStep(2)">
              <feather-icon size="24" icon="ChevronLeftIcon"/>
            </a> Hình thức thanh toán
            </h2>
        </div>
        <b-card class="complete card-installment-record">
            <div class="col-md-12">
                <div class="text-tittle">
                    Thủ tục hồ sơ trả góp
                </div>
                <div class="text-tittle-description">
                    Trả góp không qua thẻ tín dụng
                </div>
                <div class="flex-container container">
                    <div class="progress">
                        <div class="progress-bar-track" :style="progressTrack">
                            <div class="progress-bar"
                                role="progressbar"
                                aria-valuenow="60"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                :style="progress">
                            </div>
                        </div>
                        
                    </div>
                    <div class="step"
                        v-for="(step, i) in steps"
                        :key="i"
                        :class="{ done: step.number < currentStep, current: step.number === currentStep }">
                        <div class="step-number" :id="'step-'+step.number" v-on:click="moveStep(step.number)">
                            <span>{{step.number}}</span>
                        </div>
                        <div class="step-label">{{step.label}}</div>
                    </div>
                </div>
                <div class="card-installment-record-content" v-if="pageStep === 1">
                    <div class="text-record-normal">
                        Chọn ảnh giấy tờ
                    </div>
                    <div class="mt-7px choose-card-container">
                        <div class="d-flex align-items-center gap-16px">
                            <img src="../assets/images/installmentRecord/card.png" alr="" />
                            <div class="text-record-blur">
                                Căn cước dông dân (12 số)
                            </div>
                        </div>
                        <feather-icon size="16" icon="ChevronRightIcon"/>
                    </div>
                    <div class="mt-16px choose-card-container">
                        <div class="d-flex align-items-center gap-16px">
                            <img src="../assets/images/installmentRecord/card.png" alr="" />
                            <div class="text-record-blur">
                                Chứng minh nhân dân (9 số)
                            </div>
                        </div>
                        <feather-icon size="16" icon="ChevronRightIcon"/>
                    </div>
                    <div class="mt-16px text-record-normal">
                        HƯỚNG DẪN
                    </div>
                    <div class="mt-7px d-flex flex-column">
                        <div class="d-flex flex-row gap-8px align-items-center">
                            <img src="../assets/images/installmentRecord/tick.png" alt="" />
                            <div class="text-small-blur">
                                B1: Chụp ảnh giấy tờ mặt trước
                            </div>
                        </div>
                        <div class="d-flex flex-row gap-8px align-items-center mt-4px">
                            <img src="../assets/images/installmentRecord/tick.png" alt="" />
                            <div class="text-small-blur">
                                B2: Chụp ảnh giấy tờ mặt sau
                            </div>
                        </div>
                        <div class="d-flex flex-row gap-8px align-items-center mt-4px">
                            <img src="../assets/images/installmentRecord/tick.png" alt="" />
                            <div class="text-small-blur">
                                B3: Chụp ảnh chân dung KH
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-installment-record-content" v-if="pageStep === 2">
                    <div class="fs-pc mb-4px" v-if="frontImg && backImg">
                        <div class="text-record-bold">
                            Hợp đồng
                        </div>
                        <div class="mt-7px d-flex align-items-center gap-16px">
                            <img src="../assets/images/installmentRecord/record.png" alt=""/>
                            <div class="text-record-note">
                                Hợp đồng là một cam kết giữa hai hay nhiều bên để làm hoặc không làm một việc nào đó trong khuôn khổ pháp luật.
                            </div>
                        </div>
                    </div>
                    <div class="text-record-bold text-center">
                        Ảnh mặt trước
                    </div>
                    <div class="user-picture mt-11px" @click="showCapture('frontImg')">
                        <img src="../assets/images/installmentRecord/camera.png" alt="" v-if="!frontImg"/>
                        <img :src="frontImg" alt="" class="capture-picture" v-else/>
                        <button class="btn-exit-pic" v-if="frontImg" @click="onClear($event, 'frontImg')">
                            <img src="../assets/images/installmentRecord/exit.png" alt="" />
                        </button>
                    </div>
                    <div class="text-record-bold text-center mt-33px">
                        Ảnh mặt sau
                    </div>
                    <div class="user-picture mt-11px" @click="showCapture('backImg')">
                        <img src="../assets/images/installmentRecord/camera.png" alt="" v-if="!backImg"/>
                        <img :src="backImg" alt="" class="capture-picture" v-else/>
                        <button class="btn-exit-pic" v-if="backImg" @click="onClear($event, 'backImg')">
                            <img src="../assets/images/installmentRecord/exit.png" alt=""/>
                        </button>
                    </div>
                    <div class="mt-28px card-note-container" v-if="!frontImg || !backImg">
                        <div class="text-note-blue">
                            Xin vui lòng
                        </div>
                        <div class="text-note-black">
                            + Đặt giấy tờ nằm vừa khung hình chữ nhật
                        </div>
                        <div class="text-note-black">
                            + Chụp đủ ánh sáng và RÕ NÉT KHÔNG MỜ, kHÔNG MẤT GÓC, KHÔNG LÓA SÁNG
                        </div>
                    </div>
                    <div class="fs-sp mt-30px" v-if="frontImg && backImg">
                        <div class="text-record-bold">
                            Hợp đồng
                        </div>
                        <div class="mt-7px d-flex align-items-center gap-16px">
                            <img src="../assets/images/installmentRecord/record.png" alt=""/>
                            <div class="text-record-note">
                                Hợp đồng là một cam kết giữa hai hay nhiều bên để làm hoặc không làm một việc nào đó trong khuôn khổ pháp luật.
                            </div>
                        </div>
                    </div>
                    <div class="mt-30px text-record-img-invalid" v-if="!isImgValid && frontImg && backImg">
                        (*) Thông tin về tên hoặc ngày sinh chưa trùng khớp với bản khai trước đó
                        vui lòng chọn giấy tờ phù hợp hoặc nhấn nút Quay lại
                    </div>
                </div>
                <div class="card-installment-record-content" v-if="pageStep === 3">
                    <div class="fs-pc text-record-bold">
                       Xác nhận thông tin
                    </div>
                    <div class="info-group">
                        <div class="info-caption">Họ & tên</div>
                        <b-input-group>
                            <b-form-input type="text" placeholder="Họ & tên"></b-form-input>
                            <div
                                :class="`check-info-img ${confirmData.fullName ? 'check' : 'uncheck'}`"
                                @click="confirmData.fullName = !confirmData.fullName"
                            ></div>
                        </b-input-group>
                    </div>
                    <div class="info-group mt-10px">
                        <div class="info-caption">Số giấy tờ</div>
                        <b-input-group>
                            <b-form-input type="text" placeholder="Số giấy tờ"></b-form-input>
                            <div
                                :class="`check-info-img ${confirmData.idCard ? 'check' : 'uncheck'}`"
                                @click="confirmData.idCard = !confirmData.idCard"
                            ></div>
                        </b-input-group>
                    </div>
                    <div class="info-group mt-10px">
                        <div class="info-caption">Ngày sinh</div>
                        <b-input-group>
                            <b-form-input type="text" placeholder="Ngày sinh"></b-form-input>
                            <div
                                :class="`check-info-img ${confirmData.dob ? 'check' : 'uncheck'}`"
                                @click="confirmData.dob = !confirmData.dob"
                            ></div>
                        </b-input-group>
                    </div>
                    <div class="info-group mt-10px">
                        <div class="info-caption">Cấp ngày</div>
                        <b-input-group>
                            <b-form-input type="text" placeholder="Cấp ngày"></b-form-input>
                            <div
                                :class="`check-info-img ${confirmData.issueDate ? 'check' : 'uncheck'}`"
                                @click="confirmData.issueDate = !confirmData.issueDate"
                            ></div>
                        </b-input-group>
                    </div>
                    <div class="info-group mt-10px">
                        <div class="info-caption">Nơi cấp</div>
                        <b-input-group>
                            <b-form-input type="text" placeholder="Nơi cấp"></b-form-input>
                            <div
                                :class="`check-info-img ${confirmData.issuePlace ? 'check' : 'uncheck'}`"
                                @click="confirmData.issuePlace = !confirmData.issuePlace"
                            ></div>
                        </b-input-group>
                    </div>
                    <div class="info-group mt-10px">
                        <div class="info-caption">Địa chỉ</div>
                        <b-input-group>
                            <b-form-input type="text" placeholder="Địa chỉ"></b-form-input>
                            <div
                                :class="`check-info-img ${confirmData.address ? 'check' : 'uncheck'}`"
                                @click="confirmData.address = !confirmData.address"
                            ></div>
                        </b-input-group>
                    </div>
                    <div class="info-group mt-10px">
                        <div class="info-caption">Giới tính</div>
                        <v-select
                            class="select-gender"
                            label="text"
                            :filter="fuseSearch"
                            :options="gender"
                            :reduce="option => option.id"
                            placeholder="Giới tính"
                        />
                    </div>
                </div>
                <div class="card-installment-record-content" v-if="pageStep === 4">
                    <div class="text-record-bold text-center">
                        Hợp đồng trả góp
                    </div>
                    <div class="pdf-view mt-11px">
                        <button class="btn-dowload-pdf">
                            <img src="../assets/images/installmentRecord/dowload.png" alt=""/>   
                            Tải xuống PDF
                        </button>
                    </div>
                    <div class="mt-30px">
                        <label class="checkbox-term text-record-term">
                            Bằng cách tích chọn, bạn đã đồng ý với nội dung của
                            Hợp đồng trả góp và Giấy đăng ký thông tin thuê bao
                            <input type="checkbox" checked="checked">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="card-installment-record-footer-pc mt-30px">
                    <div class="btn-back" @click="onPrevStep()">
                        Quay lại
                    </div>
                    <div class="btn-continue" @click="onNextStep()">
                        Tiếp tục
                    </div>
                </div>
                <div class="card-installment-record-footer-sp mt-30px">
                    <div class="btn-continue" @click="onNextStep()">
                        Tiếp tục
                    </div>
                    <div class="btn-back" @click="onPrevStep()">
                        Quay lại
                    </div>
                </div>
            </div>
        </b-card>

        <b-modal
            body-class="p-0"
            size="lg"
            hide-footer
            id="modal-cam"
            title=""
            hide-header
            centered
        >
            <vue-web-cam
                @started="onStarted"
                @camera-change="onCameraChange"
                @cameras="onCameras"
                @stopped="onStopped"
                height="calc(100vw * 1.3)"
                width="100%"
                :selectFirstDevice="true"
                :autoplay="true"
                ref="webcam"
            ></vue-web-cam>
            <button
                class="close1"
                @click="
                    isCapture = false;
                    $bvModal.hide('modal-cam');
                "
            >
            ×
            </button>
            <button @click="capture()" class="photo1">
                <svg
                    width="60"
                    height="60"
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="30" cy="30" r="28.75" stroke="#ED1F24" stroke-width="2.5" />
                    <circle cx="30" cy="30.0001" r="25.5882" fill="#ED1F24" />
                </svg>
            </button>
            <button @click="rotate()" class="rotate1">
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="20" cy="20" r="20" fill="#C4C4C4" />
                    <path
                    d="M30.5 20.5L27.5 20.5C27.5027 19.0148 27.0642 17.5622 26.2403 16.3265C25.4164 15.0908 24.244 14.1275 22.8719 13.5587C21.4998 12.99 19.9898 12.8415 18.5331 13.132C17.0765 13.4224 15.7389 14.1389 14.69 15.1904L12.575 13.0755C14.0435 11.607 15.9144 10.607 17.9513 10.2018C19.9882 9.79661 22.0996 10.0045 24.0183 10.7993C25.937 11.594 27.5769 12.9398 28.7307 14.6665C29.8844 16.3933 30.5001 18.4233 30.5 20.5V20.5ZM27.425 27.9245C25.9565 29.393 24.0856 30.393 22.0487 30.7982C20.0118 31.2034 17.9004 30.9955 15.9817 30.2007C14.063 29.406 12.4231 28.0602 11.2693 26.3335C10.1156 24.6067 9.49985 22.5767 9.5 20.5L12.5 20.5C12.4973 21.9852 12.9358 23.4377 13.7597 24.6735C14.5836 25.9092 15.7559 26.8725 17.1281 27.4413C18.5002 28.01 20.0102 28.1585 21.4669 27.868C22.9235 27.5776 24.2611 26.8611 25.31 25.8096L27.425 27.9245V27.9245ZM5 20.5L11 14.5004L17 20.5L5 20.5ZM23 20.5L35 20.5L29 26.4996L23 20.5Z"
                    fill="black"
                    />
                </svg>
            </button>
        </b-modal>
        <b-modal body-class="p-2" title-class="text-primary" hide-footer id="modal-cod" title="" centered>
            <b-row>
                <b-col cols="12">
                <h3 class="huge-text text-center">Xác nhận OTP</h3>
                <p class="text text-center">Bạn vui lòng nhập mã OTP được gửi tới thuê bao 087xxxxxxx</p>
                <div class="pt-2" style="display: flex;align-items: center;justify-content: center;">
                    <v-otp-input
                        ref="otpInput"
                        input-classes="otp-input"
                        separator=""
                        :num-inputs="4"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        @on-complete="handleCODNext"
                    />
                </div>
                <p class="text text-center pt-2">Thời gian OTP có hiệu lực <span class="text-primary">{{ countDown }}s</span>
                </p>
                <p class="text text-center text-primary pb-3 pt-2 text-decoration-underline" role="button" @click="resendOTP">Gửi lại mã OTP</p>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import Vue from "vue";
import {
    BCardHeader,
    BCard,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BCol,
    BRow
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { WebCam } from "vue-web-cam";
import vSelect from "vue-select";
import OtpInput from "@bachdgvn/vue-otp-input";

Vue.component("v-select", vSelect);

export default {
    components: {
        BCardHeader,
        BCard,
        FeatherIcon,
        "vue-web-cam": WebCam,
        BFormGroup,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BInputGroupAppend,
        "v-otp-input": OtpInput,
        BCol,
        BRow
    },
    data() {
        return {
            steps: [
                { label: 'Lựa chọn gói trả góp', number: 1, selected: false },
                { label: 'Thủ tục hồ sơ trả góp', number: 2, selected: false },
                { label: 'Gửi duyệt hồ sơ trả góp', number: 3, selected: false },
            ],
            currentStep: 2,
            pageStep: 4,
            frontImg: null,
            backImg: null,
            isCapture: false,
            currentCapture: "",
            isImgValid: false,
            confirmData: {
                fullName: false,
                idCard: false,
                dob: false,
                issueDate: false,
                issuePlace: false,
                address: false
            },
            deviceId: null,
            devices: [],
            deviceIndex: 0,
            gender: [
                { id: 1, text: "Nam" },
                { id: 2, text: "Nữ" },
            ],
            countDown: 60
        }
    },
    mounted() {
        this.countDownTimer();
    },
    computed: {
        progress() {
            if (this.currentStep > this.steps.length) {
                return `width: 100%`;
            }
            const first = document.getElementById('step-1');
            const current = document.getElementById(`step-${this.currentStep}`);
            if (first && current) {
                const delta = current.getBoundingClientRect().right -first.getBoundingClientRect().right;
                return `width: ${delta}px;`;
            }
        },
        progressTrack() {
            if (this.currentStep > this.steps.length) {
                return `width: 100%`;
            }
            
            const first = document.getElementById('step-1');
            const current = document.getElementById(`step-${this.steps.length}`);
            if (first && current) {
                const delta = current.getBoundingClientRect().right -first.getBoundingClientRect().right;
                return `width: ${delta}px;`;
            }
        }
    },
    methods: {
        countDownTimer() {
            setTimeout(() => {
                this.countDown = this.countDown > 1 ? this.countDown - 1 : 0
                this.countDownTimer()
            }, 1000)
        },
        onNextStep(){
            if(this.pageStep === 4){
                this.countDown = 60
                this.$bvModal.show("modal-cod");
            }

            if(this.pageStep < 4){
                this.pageStep++
            }
        },
        onPrevStep(){
            if(this.pageStep > 1){
                this.pageStep--
            }
        },
        moveStep(stepNumber) {
            this.currentStep = stepNumber;
        },
        showCapture(type) {
            this.currentCapture = type;
            this.isCapture = true;
            this.$bvModal.show("modal-cam");
        },
        capture(srcImg = null) {
            var img = srcImg ? srcImg : this.$refs.webcam.capture();
            this[this.currentCapture] = img;

            this.$bvModal.hide("modal-cam");
        },
        onClear(e, type){
            e.stopPropagation();
            this[type] = null;
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            console.log("On Camera Change Event", deviceId);
        },
        onStarted(stream) {},
        rotate() {
            console.log(this.deviceId);
            this.deviceIndex++;
            if (this.deviceIndex > this.devices.length - 1) {
                this.deviceIndex = 0;
            }
            var c = this.devices.find(
                (i) => i.deviceId != this.deviceId && this.devices.indexOf(i) >= this.deviceIndex
            );
            if (c != undefined) {
                this.$refs.webcam.changeCamera(c.deviceId);
            }
        },
        onCameras(cameras) {
            this.devices = cameras;
            console.log("On Cameras Event", cameras);
        },
        onStopped() {
            this.isCapture = false;
        },
        fuseSearch(options, search) {
            const fuse = new Fuse(options, {
                keys: ["text", "text1"],
                shouldSort: true,
            });
            return search.length
                ? fuse.search(search).map(({item}) => item)
                : fuse.list;
        },
        handleCODNext(otp_) {
            this.$toast.error(
                "Sai mã OTP, vui lòng thử lại.",
                {icon: true, closeButton: "button"}
            );
        },
        resendOTP() {
        
        }
    }
}
</script>
<style lang="scss">
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.otp-input.error {
  border: 1px solid red !important;
}

.select-gender{
    .vs__dropdown-toggle{
        height: 5rem;
        border-radius: 1rem;
    }
    .vs__search{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #151522;
        &::placeholder{
            font-family: 'Roboto';
            font-style: italic;
            font-weight: 300;
            font-size: 10px;
            line-height: 16px;
            color: #151522;
        }
    }
    .vs__selected{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #151522;
    }
}
.huge-text {
  font-size: 24px !important;
  font-weight: 700 !important;
  margin-bottom: 16px;
}
</style>
<style lang="scss" scoped>
.text-decoration-underline{
    text-decoration: underline;
}
.gap-16px{
    gap: 16px;
}
.gap-8px{
    gap: 8px;
}
.mt-7px{
    margin-top: 7px;
}
.mt-4px{
    margin-top: 4px;
}
.mb-4px{
    margin-bottom: 4px;
}
.mt-11px{
    margin-top: 11px;
}
.mt-10px{
    margin-top: 10px;
}
.mt-16px{
    margin-top: 16px;
}
.mt-30px{
    margin-top: 30px;
}
.mt-33px{
    margin-top: 33px;
}
.mt-28px{
    margin-top: 28px;
}
.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
  padding-bottom: 115px;
}
.b2c-pc-header {
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  border-bottom: 1px solid #dadada;
  width: 100%;
}
.b2c-pc-header {
  display: flex;
}
.b2c-pc-header h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 69px;
  color: #424242;
}
.complete {
  margin-left: auto;
  margin-right: auto;
  max-width: 562px;
}
.complete .card-body {
  padding: 0 !important;
}
.close1 {
  position: absolute;
  right: 15px;
  top: 6px;
  font-weight: 400;
  font-size: 5rem;
  color: #333;
}
.photo1 {
  position: absolute;
  right: 40%;
  left: 40%;
  bottom: 10px;
}
.rotate1 {
  position: absolute;
  right: 25px;
  bottom: 20px;
}
.card-installment-record{
    margin-top: 64px !important;
    margin-bottom: 0 !important;
    padding: 32px;
    .text-tittle{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        color: #4B4B4B;
    }
    .text-tittle-description{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #4B4B4B;
    }
    .flex-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1em;
        position: relative;

        margin-top: 17px;
        
        .progress {
            position: absolute;
            height: 5px;
            margin: 0;
            box-shadow: none;
            background: #F7F8FC;
            border: 1px solid #EFF1F7;
            top: 18px;
            width: calc(100% - 32px);
            .progress-bar {
                background-color: #FF0000;
                position: absolute;
                height: 5px;
                top: -1px;

                width: 50%;
            }
            .progress-bar-track{
                background: #F7F8FC;
                border: 1px solid #EFF1F7;
                height: 5px;
            }
        }
        .step {
            text-align: center;
            z-index: 2;
            .step-number {
                background-color: #FF0000;
                padding: 0.5em;
                color: white;
                border-radius: 2em;
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                span{
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 800;
                    font-size: 12px;
                    line-height: 14px;
                    color: #FFFFFF;
                }
            }
            
            &.current {
                .step-number {
                    background-size: 200% 200%;
                    transition: all .4s .5s;
                }
            }
            &.done {
                .step-number { background-color: #FF0000; }
            }
            .step-label {
                padding-top: 6px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 7.78378px;
                line-height: 9px;
                text-align: center;
                color: #454545;
                max-width: 50px;
                white-space: break-spaces;
                position: relative;
                transform: translateX(-8px);
            }
        }
    }
    .card-installment-record-content{
        margin-top: 30px;
        .text-record-normal{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;
            color: #151522;
        }
        .text-record-blur{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 13.4268px;
            line-height: 129.4%;
            color: #424242;
        }
        .text-small-blur{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 9.90186px;
            line-height: 17px;
            color: #424242;
        }
        .choose-card-container{
            padding: 13px;
            background: #FFFFFF;
            border: 0.839174px solid rgba(0, 0, 0, 0.1);
            border-radius: 8.39174px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
        }
        .text-record-bold{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 15px;
            color: #222222;
        }
        .user-picture{
            width: 240px;
            min-height: 140px;
            max-width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #FFFFFF;
            box-shadow: 0px 2.56604px 25.6604px rgba(0, 0, 0, 0.1);
            border-radius: 5.13208px;
            margin-left: auto;
            margin-right: auto;
            position: relative;

            .capture-picture{
                max-width: 100%;
                border-radius: 5.13208px;
            }
            .btn-exit-pic{
                position: absolute;
                top: 0;
                right: 0;
                transform: translateX(50%) translateY(-50%);

                width: 25px;
                height: 25px;
                background: #FFC4C4;
                border-radius: 50%;
                opacity: 0.4;
            }
        }
        .card-note-container{
            width: 240px;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
            padding: 14px 12px 14px 24px;
            background: #FFFFFF;
            border: 0.600398px solid #18D3C9;
            border-radius: 4.80318px;

            .text-note-blue{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 11px;
                line-height: 14px;
                color: #18D3C9;
            }
            .text-note-black{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                line-height: 14px;
                color: #000000;
            }
        }
        .text-record-note{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 11.2357px;
            line-height: 19px;
            color: #000000;
        }
        .text-record-img-invalid{
            font-family: 'Roboto';
            font-style: italic;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            text-align: center;

            color: #ED1F24;
        }
        .info-group{
            position: relative;
            max-width: 320px;
            margin-left: auto;
            margin-right: auto;
            .info-caption{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 12.0042px;
                line-height: 16px;
                color: #151522;
                margin-bottom: 6px;
            }
            .form-control{
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #151522;
                    &::placeholder{
                        font-family: 'Roboto';
                        font-style: italic;
                        font-weight: 300;
                        font-size: 10px;
                        line-height: 16px;
                        color: #151522;
                    }
            }
            .check-info-img{
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                width: 18px;
                height: 18px;
                &.check{
                    background-image: url('../assets/images/installmentRecord/check.png');
                }
                &.uncheck{
                    background-image: url('../assets/images/installmentRecord/uncheck.png');
                }
            }
        }
        .pdf-view{
            width: 249.27px;
            height: 344.23px;
            max-width: 100%;
            background: #E2E2E2;
            border-radius: 8px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            .btn-dowload-pdf{
                height: 23px;
                background: #FFFFFF;
                border: 0.8px solid #ED1F24;
                box-shadow: 0px 3px 5px rgba(50, 50, 71, 0.25);
                border-radius: 6px;
                padding: 0 7px;

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }
        .text-record-term{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #151522;
        }
        .checkbox-term{
            -webkit-appearance: checkbox;
            max-width: 320px;
            margin-left: auto;
            margin-right: auto;
        }
        .checkbox-term {
            display: block;
            position: relative;
            padding-left: 35px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        .checkbox-term input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 24px;
            width: 24px;
            background-color: #eee;
            width: 24px;
            height: 24px;
            box-shadow: 0px 4px 4px rgba(50, 50, 71, 0.08), 0px 4px 8px rgba(50, 50, 71, 0.06);
            border-radius: 6px;
        }
        .checkbox-term:hover input ~ .checkmark {
            background-color: #ccc;
        }
        .checkbox-term input:checked ~ .checkmark {
            background-color: #ED1F24;
        }
        .checkmark:after {
        content: "";
            position: absolute;
            display: none;
        }
        .checkbox-term input:checked ~ .checkmark:after {
            display: block;
        }
        .checkbox-term .checkmark:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
    .btn-back{
        background: #FFFFFF;
        border: 1.12253px solid #ED1F24;
        border-radius: 24.0355px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14.9671px;
        line-height: 16px;
        color: #ED1F24;
        cursor: pointer;

        width: auto;
        flex: 50%;
    }
    .btn-continue{
        background: #ED1F24;
        border: 1.12253px solid #ED1F24;
        border-radius: 24.0355px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14.9671px;
        line-height: 16px;
        color: #FFFFFF;
        cursor: pointer;
        
        width: auto;
        flex: 50%;
    }
    .card-installment-record-footer-pc{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 1;
    }
    .card-installment-record-footer-sp{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 1;
        flex-direction: column;
        display: none;
        .btn-back, .btn-continue{
            width: 100%;
            flex: unset;
        }
    }
}
@media screen and (max-width: 768px){
    .b2c-page-bg{
        background: #FFFFFF;
        padding-bottom: 0;
    }
    .card-installment-record{
        margin: 0 !important;
        border-radius: 0 !important;
        .card-installment-record-footer-pc{
            display: none;
        }
        .card-installment-record-footer-sp{
            display: flex;
        }
    }
}
</style>